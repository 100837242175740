export const EPath = {
  // NotFoundPage
  notFoundPage: '/notfound',

  // LoginPage
  loginPage: '/login',

  // create workspace
  omoteuraDiagnosis: '/omoteura-diagnosis-result',
  omoteuraDiagnosi: '/implements/omoteura-diagnosis-result/:employeeId/:eventId',
  omoteuraDiagnosiConsultant: '/interviews/omoteura-diagnosis-result/:employeeId/:eventId',
  omoteuraEmployeeAnswer: '/implements/omoteura-employee-answer/:employeeId/:eventId',
  omoteuraUpdateResult: '/implements/omoteura-update-result/:employeeId/:eventId',
  analyzeOmoteraDiagnosisResult: '/analyzeOmoteraDiagnosisResult',
  createAnalyzeOmoteraDiagnosisResult: '/analyzeOmoteraDiagnosisResult/create',
  analyzeReportDetail: '/analyzeOmoteraDiagnosisResult/:reportId',
  comparisonAxis: '/comparison-axis',

  // ID Master Management
  Client_ID_Management: '/client-management',
  Manager_ID_Management: '/client-management/:clientId/manager-management',
  Facility_ID_Management: '/client-management/:clientId/facility-management',
  Com_ID_Management: '/.com-management',
  Employ_ID_Management: '/client-management/:clientId/:clientName/employ-management',
  Interviewer_ID_Management: '/interviewer-management',
  CareerMemoRegister: '/career-memo-register',
  Data_download: '/data-download',
  // a
  a: '/a',

  // b
  b: '/b',
  // icon
  icon: '/icon',

  // implements
  implements: '/implements',
  implementDetail: '/implements/:implementId',
  implementGroupDetail: '/implements/group/:bulkCode',
  message: '/message',

  // consultant
  interviews: '/interviews',
  interviewDetail: '/interviews/:interviewId',
  Interview_Management: '/interview-management',
  Interview_Management_id: '/interview-management/:consultantId',
  Interview_Manual: '/interview-manual',
  Account_setting: '/account-setting',

  // employeesWeb
  employeeLogin: '/employee-login',
  employeeIdentity: '/employee-identity',
  employeeInitialPass: '/employee-initialPass',
  employeeWebHome: '/employee-home',
  employeeWebHomeStart: '/employee-home-start',
  employeeWebHomeFullStart: '/employee-home-full-start',
  employeeWebHomeFullStartTest: '/employee-home-full-start-test',
  employeeWebHomeStartTest: '/employee-home-start-test',
  employeeWebHomeComplete: '/employee-home-complete',
};
