import React, { Fragment, lazy, Suspense, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { IndexedObject, PropsLayout } from 'types/common';
import { EPath } from './routesConfig';
import { MyLayout } from 'app/pages/Layout/Layout';
import { Spin } from 'antd';
import { v4 as uuid } from 'uuid';
import { getUserFromCookie } from '../../helpers/cookies';

export type RoutesProps = {
  exact?: boolean;
  path: string;
  component: React.FC<{ history: IndexedObject; location: IndexedObject; match: IndexedObject }>;
  auth?: boolean;
  routes?: Array<RoutesProps>;
  layout?: React.FC<PropsLayout>;
  roles?: Array<string>;
};

const RenderRoutes = ({
  routes,
  isAuthenticated,
  currentUser,
}: {
  routes: Array<RoutesProps>;
  isAuthenticated: boolean;
  currentUser: IndexedObject;
}) => {
  const getHomePageByRole = () => {
    return currentUser?.consultant ? (
      <Navigate to={EPath.interviews} />
    ) : (
      <Navigate to={EPath.implements} />
    );
  };

  return (
    <Suspense
      fallback={
        <div className="spin">
          <Spin size="large" />
        </div>
      }
    >
      <Routes>
        {routes.map((route) => {
          const LayoutRoute = route.layout || Fragment;
          const Component = route.component || <div />;
          if (!!route.auth && !isAuthenticated) {
            return (
              <Route
                key={uuid()}
                path={route.path}
                element={<Navigate key={uuid()} to={EPath.loginPage} replace />}
              />
            );
          }
          return (
            <Route
              key={uuid()}
              path={route.path}
              element={
                <LayoutRoute>
                  {route.routes ? (
                    <RenderRoutes
                      currentUser={currentUser}
                      routes={route.routes}
                      isAuthenticated={isAuthenticated}
                    />
                  ) : (
                    <>
                      {!!route?.roles ? (
                        <>
                          {route.roles?.includes(currentUser?.role) ? (
                            <Component history={{}} location={{}} match={{}} />
                          ) : (
                            getHomePageByRole()
                          )}
                        </>
                      ) : (
                        <Component history={{}} location={{}} match={{}} />
                      )}
                    </>
                  )}
                </LayoutRoute>
              }
            />
          );
        })}
      </Routes>
    </Suspense>
  );
};

export const currentUser = getUserFromCookie();

export const routes = [
  {
    path: EPath.loginPage,
    component: lazy(() => import('../pages/Login/LoginPage')),
  },
  {
    path: EPath.employeeLogin,
    component: lazy(() => import('../pages/EmployeeLogin/employeeLoginPage')),
  },
  {
    path: '*',
    layout: MyLayout,
    component: () => <Navigate to={EPath.omoteuraDiagnosis} />,
    routes: [
      {
        path: '/',
        component: () => {
          return currentUser.consultant ? (
            <Navigate to={EPath.interviews} />
          ) : (
            <Navigate to={EPath.implements} />
          );
        },
        auth: true,
      },
      {
        path: EPath.omoteuraDiagnosis,
        component: lazy(() => import('../pages/OmoteraDiagnosisResultPage')),
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeIndentity/EmployeeIndentity')),
        path: EPath.employeeIdentity,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeWebInitialPass/EmployeeWebInitialPass')),
        path: EPath.employeeInitialPass,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeWebHome/EmployeeWebHome')),
        path: EPath.employeeWebHome,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeWebStart/EmployeeWebStart')),
        path: EPath.employeeWebHomeStart,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeWebComplete/EmployeeWebComplete')),
        path: EPath.employeeWebHomeComplete,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(
          () => import('../pages/EmployeeWebFullfilmentStart/EmployeeWebFullFilmentStart'),
        ),
        path: EPath.employeeWebHomeFullStart,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeWebTest/EmployeeWebTest')),
        path: EPath.employeeWebHomeStartTest,
        roles: ['employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployeeWebFullTest/EmployeeWebFullTest')),
        path: EPath.employeeWebHomeFullStartTest,
        roles: ['employee'],
        auth: true,
      },
      {
        path: EPath.omoteuraDiagnosi,
        component: lazy(() => import('../pages/OmoteraDiagnosisResultPage')),
        auth: true,
        roles: ['com', 'client_manager', 'consultant', 'employee'],
      },
      {
        path: EPath.omoteuraDiagnosiConsultant,
        component: lazy(() => import('../pages/OmoteraDiagnosisResultPage')),
        auth: true,
        roles: ['com', 'client_manager', 'consultant', 'employee'],
      },
      {
        component: lazy(() => import('../pages/Implements/Implements')),
        path: EPath.implements,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/ClientIDManagement/ClientIDManagement')),
        path: EPath.Client_ID_Management,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/ManagerIDManagement/ManagerIDManagement')),
        path: EPath.Manager_ID_Management,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/FacilityIDManagement/FacilityIDManagement')),
        path: EPath.Facility_ID_Management,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/ComIDManagement/ComIDManagement')),
        path: EPath.Com_ID_Management,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/EmployIDManagement/EmployIDManagement')),
        path: EPath.Employ_ID_Management,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/InterviewerIDManagement/InterviewerIDManagement')),
        path: EPath.Interviewer_ID_Management,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/CareerMemoRegister/CareerMemoRegister')),
        path: EPath.CareerMemoRegister,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(
          () =>
            import('../pages/Implements/ImplementationMeetingDetail/ImplementationMeetingDetail'),
        ),
        path: EPath.implementDetail,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(
          () =>
            import('../pages/Implements/ImplementationMeetingGroupDetail/ImplementationMeetingGroupDetail'),
        ),
        path: EPath.implementGroupDetail,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        path: EPath.interviews,
        component: lazy(() => import('../pages/Consultant/MyPage/MyPage')),
        auth: true,
        roles: ['consultant'],
      },
      {
        component: lazy(() => import('../pages/Consultant/MyPage/InterviewDetail/InterviewDetail')),
        path: EPath.interviewDetail,
        roles: ['consultant'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/Chat/Chat')),
        path: EPath.message,
        roles: ['com', 'consultant'],
        auth: true,
      },
      {
        component: lazy(
          () => import('../pages/Consultant/InterviewManagement/InterviewManagement'),
        ),
        path: EPath.Interview_Management,
        roles: ['consultant'],
        auth: true,
      },
      {
        component: lazy(
          () => import('../pages/Consultant/InterviewManagement/InterviewManagement'),
        ),
        path: EPath.Interview_Management_id,
        roles: ['com', 'consultant'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/Consultant/AccountSetting/AccountSetting')),
        path: EPath.Account_setting,
        roles: ['consultant'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/DataDownload/DataDownload')),
        path: EPath.Data_download,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(
          () => import('../pages/AnalyzeOmoteraDiagnosisResult/AnalyzeOmoteraDiagnosisResult'),
        ),
        path: EPath.analyzeOmoteraDiagnosisResult,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(
          () => import('../pages/AnalyzeOmoteraDiagnosisResult/CreateAnalyzeOmoteuraResult'),
        ),
        path: EPath.createAnalyzeOmoteraDiagnosisResult,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(
          () => import('../pages/AnalyzeOmoteraDiagnosisResult/CreateAnalyzeOmoteuraResult'),
        ),
        path: EPath.analyzeReportDetail,
        roles: ['com', 'client_manager', 'employee'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/ComparisonBetween16Axis/ComparisonBetween16Axis')),
        path: EPath.comparisonAxis,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/OmoteraShowAnswer/OmoteraShowAnswer')),
        path: EPath.omoteuraEmployeeAnswer,
        roles: ['com'],
        auth: true,
      },
      {
        component: lazy(() => import('../pages/OmoteraUpdateResult/OmoteraUpdateResult')),
        path: EPath.omoteuraUpdateResult,
        roles: ['com'],
        auth: true,
      },
      {
        path: '*',
        component: () => {
          return currentUser.consultant ? (
            <Navigate to={EPath.interviews} />
          ) : (
            <Navigate to={EPath.implements} />
          );
        },

        auth: true,
      },
    ],
  },
  {
    path: EPath.icon,
    auth: true,
    component: lazy(() => import('../pages/IconPreview/IconPreview')),
  },
  {
    path: EPath.notFoundPage,
    component: lazy(() => import('../components/NotFoundPage/NotFoundPage')),
    auth: true,
  },
];

export default RenderRoutes;
